import { Fragment, useEffect, useRef, useState } from "react";
import { Content, SelectableContent } from "../Help/useHelp";
import {
  IconButton,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import createImagePlugin from "../../plugins/image-plugin";
import createAudioPlugin from "../../plugins/audio-plugin";
import createSliderPlugin from "../../plugins/slider-plugin";
import createVideoPlugin from "@draft-js-plugins/video";
import handleKeyboard from "../../shared/tools/button-keyhandler";
import showLinkDecorator from "../../plugins/link-plugin";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import Editor from "@draft-js-plugins/editor";
import { SupportIcon } from "../../shared/icons";
import { LinkExternalIcon } from "../../shared/icons";
import { DocIcon } from "../../shared/icons";
import { getWindowPosition } from "../../shared/tools/helpers";

export type PositionProps = {
  windowPosition: PositionOptions;
};

export type PositionOptions =
  | "bottomRight"
  | "bottomLeft"
  | "topRight"
  | "topLeft";

type HelpContentProps = {
  isDesktop: boolean;
  onBackClick: (e: any) => void;
  content: Content | null;
  selectableContent: SelectableContent;
  selectableTags: string[] | null;
  onSelectableTagClick: (value: string) => void;
  onSelectableContentClick: (content: Content) => void;
  goToSupport: () => void;
  closeHelp: () => void;
  position: PositionProps;
};

type WindowPositionProps = {
  top: string;
  left: string;
};

const imagePlugin = createImagePlugin({
  className: "aida-image-plugin",
  fullscreenIcon: <DocIcon size="32" />,
});
const sliderPlugin = createSliderPlugin({
  className: "aida-slider-plugin",
});
const audioPlugin = createAudioPlugin({
  className: "aida-audio-plugin",
});
const videoPlugin = createVideoPlugin();

const NOOP = () => {};

const HelpContent = ({
  isDesktop,
  onBackClick,
  content,
  selectableContent,
  selectableTags,
  onSelectableTagClick,
  onSelectableContentClick,
  goToSupport,
  closeHelp,
  position,
}: HelpContentProps) => {
  const [windowPosition, setWindowPosition] = useState<
    WindowPositionProps | {}
  >({});
  const helpContent = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (helpContent.current && !Object.keys(windowPosition).length) {
      const a = getWindowPosition(
        helpContent.current,
        position.windowPosition
      ) as WindowPositionProps;
      setWindowPosition(a);
    }
  }, [helpContent, position.windowPosition, windowPosition]);

  useEffect(() => {
    if (contentRef.current !== null) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [content]);

  const canGoBack = !selectableTags;
  const newContent = content && showLinkDecorator(content.content);

  const renderTitle = () => {
    if (content) {
      return content.title;
    }
    if (selectableContent && selectableContent.title) {
      return selectableContent.title;
    }
    return "Hjälp";
  };

  return (
    <div
      style={
        isDesktop
          ? {
              position: "relative",
              ...windowPosition,
            }
          : {}
      }
      ref={helpContent}
      className={isDesktop ? "help-container" : "help-container-mobile"}
    >
      <div className="help-header handle">
        <div className="help-header-container">
          <IconButton
            className="help-header-button"
            aria-label="Gå tillbaka"
            style={{
              visibility: canGoBack ? "visible" : "hidden",
              cursor: canGoBack ? "pointer" : "default",
              color: "white",
            }}
            onClick={onBackClick}
          >
            <ArrowBack />
          </IconButton>
          <Typography component="h4" className="help-header-title">
            {renderTitle()}
          </Typography>
          <IconButton
            aria-label="Stäng hjälp"
            className="help-header-button"
            onClick={closeHelp}
            style={{ color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="help-body-container" ref={contentRef}>
        {newContent && (
          <Editor
            editorState={newContent}
            readOnly
            onChange={NOOP}
            plugins={[imagePlugin, audioPlugin, videoPlugin, sliderPlugin]}
          />
        )}

        <List component="div" className="help-selectable-list">
          {selectableContent &&
            selectableContent.content &&
            !content &&
            selectableContent.content.map((selectable: Content) => (
              <Fragment key={selectable.title}>
                <ListItem
                  button
                  aria-label="Välj innehåll"
                  className="help-selectable-listitem"
                  onClick={() => onSelectableContentClick(selectable)}
                >
                  <ListItemIcon className="help-selectable-icon">
                    <DocIcon size="32" />
                  </ListItemIcon>
                  <ListItemText primary={selectable.title} />
                </ListItem>
              </Fragment>
            ))}
          {selectableTags &&
            selectableTags.map((tag: string) => (
              <Fragment key={tag}>
                <ListItem
                  button
                  aria-label="Välj innehåll"
                  className="help-selectable-listitem"
                  onClick={() => onSelectableTagClick(tag)}
                >
                  <ListItemIcon className="help-selectable-icon">
                    <DocIcon size="32" />
                  </ListItemIcon>
                  <ListItemText primary={tag} />
                </ListItem>
              </Fragment>
            ))}
        </List>
        <div
          className="help-support-container"
          onClick={goToSupport}
          role="button"
          tabIndex={0}
          onKeyUp={handleKeyboard(goToSupport)}
        >
          <Grid container spacing={2}>
            <Grid item className="help-support-icon">
              <SupportIcon size="32" />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography variant="body2" gutterBottom>
                    Kundservice
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Har du stött på problem? Vi hjälper dig gärna
                  </Typography>
                </Grid>
                <Grid item>
                  <span className="help-support-contact-us">Kontakta oss</span>
                </Grid>
              </Grid>
              <Grid item>
                <div className="help-call-made help-call-made-icon">
                  <LinkExternalIcon size="32" />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default HelpContent;
