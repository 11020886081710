import React from "react";
import SliderComponent from "./ImageSlider";

function createSliderPlugin({ className }) {
  return {
    blockRendererFn: (block, { getEditorState, getReadOnly }) => {
      if (block.getType() !== "atomic") {
        return null;
      }

      const contentState = getEditorState().getCurrentContent();
      const entity = block.getEntityAt(0);

      if (!entity) {
        return null;
      }

      const type = contentState.getEntity(entity).getType();

      if (type !== "IMAGESLIDER" && type !== "imageslider") {
        return null;
      }

      const Slider = (props) => (
        <SliderComponent
          className={className}
          readOnly={getReadOnly()}
          {...props}
        />
      );

      return {
        component: Slider,
        editable: false,
      };
    },
  };
}

export default createSliderPlugin;
