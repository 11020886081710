import { useState } from "react";

import { addImage } from './modifiers'

const StyleButton = ({ onClick, label }) => {
  const className = "RichEditor-styleButton";

  return (
    <button className={className} onClick={() => onClick()}>
      {label}
    </button>
  );
};

const AddImage = ({ editorState, onChange }) => {
  const [showInput, toggleShowInput] = useState(false);
  const [image, setImage] = useState("");

  const add = () => {
    onChange(addImage(editorState, image, {}));
    toggleShowInput(false);
  }

  return (
    <div className="RichEditor-addImage">
      <StyleButton label="IMG" onClick={() => toggleShowInput(!showInput)} />
      {showInput && (
        <input
          type="text"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          onBlur={add}
          placeholder="url"
        />
      )}
    </div>
  );
};

export default AddImage;
