import { IconType } from "./index";

const Support = ({ size, color } : IconType) => (
  <svg
    viewBox="0 0 32 32"
    width={`${size}px`}
    fill={color}
    className="support-icon"
  >
  <g stroke="#000" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
    <path vectorEffect="non-scaling-stroke" d="M8.174 22.26H7.39a3.13 3.13 0 0 1-3.13-3.13V16a3.13 3.13 0 0 1 3.13-3.13h.783c.432 0 .783.35.783.782v7.826c0 .432-.35.783-.783.783zM24.609 22.26h-.783a.783.783 0 0 1-.783-.782v-7.826c0-.432.35-.782.783-.782h.783a3.13 3.13 0 0 1 3.13 3.13v3.13a3.13 3.13 0 0 1-3.13 3.13zM7.391 12.87A8.609 8.609 0 0 1 16 4.26h0a8.609 8.609 0 0 1 8.609 8.61M19.13 26.174h2.348a3.13 3.13 0 0 0 3.13-3.13h0v-.783"/>
    <path vectorEffect="non-scaling-stroke" d="M17.565 27.74H16c-.864 0-1.565-.702-1.565-1.566h0c0-.865.7-1.565 1.565-1.565h1.565c.865 0 1.565.7 1.565 1.565h0c0 .864-.7 1.565-1.565 1.565zM12.87 19.592a4.648 4.648 0 0 0 6.26 0"/>
    <path d="M12.87 13.897v1.369M19.13 13.897v1.369"/>
  </g>
</svg>
);

export default Support;