import React from "react";

const ChevronRight = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-chevronright"
  >
    <path
      transform="translate(25.500000, 25.000000) rotate(-180.000000) translate(-25.500000, -25.000000) "
      d="M37.8195634,49.6059879 L38.6080823,48.8135645 C39.1306392,48.2883457 39.1306392,47.4367427 38.6080823,46.9114119 L16.80778,24.999951 L38.6080823,3.08848999 C39.1306392,2.56327129 39.1306392,1.71166827 38.6080823,1.18633748 L37.8195634,0.393914031 C37.2970065,-0.131304677 36.4497194,-0.131304677 35.9271625,0.393914031 L12.3919177,24.0489307 C11.8693608,24.5741495 11.8693608,25.4257525 12.3919177,25.9510833 L35.9271625,49.6061 C36.4497194,50.1313187 37.2970065,50.1313187 37.8195634,49.6059879 Z"
    />
  </svg>
);

export default ChevronRight;
