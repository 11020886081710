import React, { useState, useMemo } from "react";
import { ChevronLeft, ChevronRight } from "../../shared/icons";
import Editor from "@draft-js-plugins/editor";
import { EditorState, convertFromRaw } from "draft-js";
import showLinkDecorator from "../link-plugin";

const NOOP = () => {};

const Slider = ({ slides, settings, clickHandler }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const slideBack = (e) => {
    e.stopPropagation();
    const slidesLength = slides.length;
    const newActiveIndex = (activeSlideIndex + slidesLength - 1) % slidesLength;
    setActiveSlideIndex(newActiveIndex);
  };

  const slideForward = (e) => {
    e.stopPropagation();
    const slidesLength = slides.length;
    const newActiveIndex = (activeSlideIndex + slidesLength + 1) % slidesLength;
    setActiveSlideIndex(newActiveIndex);
  };

  const gotoSlide = (index) => {
    setActiveSlideIndex(index);
  };

  const convertToDraftJs = (contentToConvert) => {
    if (!contentToConvert) {
      return EditorState.createEmpty();
    }
    const raw = convertFromRaw(contentToConvert);
    const editorState = EditorState.createWithContent(raw);

    return showLinkDecorator(editorState);
  };

  const convertSlideTextToDraftJS = useMemo(
    () =>
      slides &&
      convertToDraftJs(
        slides[activeSlideIndex] && slides[activeSlideIndex].text
      ),
    [slides, activeSlideIndex]
  );

  return (
    <div className="image-slider-wrapper">
      {slides && (
        <div className="slides-footer-wrapper">
          <div
            className="slides-container"
            onClick={() => clickHandler && clickHandler(true)}
          >
            <div
              className="slides"
              style={{ left: `-${activeSlideIndex * 100}%` }}
            >
              {slides.map((slide, index) => {
                return (
                  <div className="slide" key={`image_${index}`}>
                    <div className="slide-inner">
                      <img role="presentation" src={slide.src} />
                    </div>
                  </div>
                );
              })}
            </div>
            <button className="navigation-btn prev" onClick={slideBack}>
              <ChevronLeft className="slide-icon" size="20" />
            </button>
            <button className="navigation-btn next" onClick={slideForward}>
              <ChevronRight className="slide-icon" size="20" />
            </button>
            <div className="slider-btns">
              <div className="slider-btns-inner">
                {slides.map((slide, index) => (
                  <button
                    style={{
                      backgroundColor: `${
                        activeSlideIndex === index ? "#26b3ac" : "transparent"
                      }`,
                    }}
                    className="slide-btn"
                    key={`btn_slide_${index}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      gotoSlide(index);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="slider-footer">
            <div className="slider-text-wrapper">
              <Editor
                className="slider-text"
                editorState={convertSlideTextToDraftJS}
                readOnly
                onChange={NOOP}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slider;
