import React from "react"
import ImageComponent from "./Image";
import { addImage } from "./modifiers";
import AddImage from "./AddImage";

function createImagePlugin({ className, fullscreenIcon }) {
  return {
    blockRendererFn: (block, { getEditorState, getReadOnly }) => {
      if (block.getType() !== "atomic") {
        return null;
      }

      const contentState = getEditorState().getCurrentContent();
      const entity = block.getEntityAt(0);

      if (!entity) {
        return null;
      }

      const type = contentState.getEntity(entity).getType();

      if (type !== "IMAGE" && type !== "image") {
        return null;
      }

      const Image = (props) => (
        <ImageComponent
          className={className}
          fullscreenIcon={fullscreenIcon}
          readOnly={getReadOnly()}
          {...props}
        />
      );

      return {
        component: Image,
        editable: false,
      };
    },
    addImage,
    AddImage,
  };
}

export default createImagePlugin;
