import { IconType } from "./index";

const Doc = ({ size, color } : IconType) => (
  <svg
    viewBox="0 0 32 32"
    width={`${size}px`}
    fill={color}
    className="link-external-icon"
  >
  <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
    <path d="M26.5 25.75a1.5 1.5 0 0 1-1.5 1.5H7a1.5 1.5 0 0 1-1.5-1.5V6.25A1.5 1.5 0 0 1 7 4.75h15.045c.392 0 .768.153 1.048.426l2.954 2.883c.29.282.453.67.453 1.074V25.75zM10.045 12.25h12M10.045 16.75h12M10.045 21.25h6"/>
  </g>
</svg>
);

export default Doc;