
import React from "react";
const Audio = ({
  block,
  contentState,
  className,
  readOnly
}) => {
  const { src, alt } = contentState.getEntity(block.getEntityAt(0)).getData();

  const audio = (
    <audio controls src={src} alt={alt} className={className} />
  );

  if (readOnly) {
    return (
      <>
        <div className={`wrapper-${className}`}>
          {audio}
        </div>
      </>
    );
  }

  return audio;
};

export default Audio;
