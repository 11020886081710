function handleKeyboard(fn: (event: React.KeyboardEvent) => void) {
  return (event: React.KeyboardEvent) => {
    if (
      event.key === " " ||
      event.key === "Enter" ||
      event.key === "Spacebar"
    ) {
      event.preventDefault()
      fn(event)
    }
  }
}

export default handleKeyboard
