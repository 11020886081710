import React, { useState } from "react";
import FullscreenImage from "./FullscreenImage";

const Image = ({
  block,
  contentState,
  className,
  readOnly,
  fullscreenIcon,
}) => {
  const [fullscreen, toggleFullscreen] = useState(false);

  const { src, alt } = contentState.getEntity(block.getEntityAt(0)).getData();

  const img = (
    <img role="presentation" src={src} alt={alt} className={className} />
  );

  if (readOnly) {
    return (
      <>
        <div className={`wrapper-${className}`}>
          {img}
          <button type="button" className={`overlay-${className}`} onClick={() => toggleFullscreen(true)}>
            {fullscreenIcon}
          </button>
        </div>
        {fullscreen && <FullscreenImage src={src} alt={alt} onClose={() => toggleFullscreen(false)}/>}
      </>
    );
  }

  return img;
};

export default Image;
