import React, { useEffect, useRef } from "react"
import { Portal } from "@material-ui/core"

const FullscreenImage = ({ src, alt, onClose }) => {
  const fullScreenDiv = useRef()

  useEffect(() => {
    let fullscreenContainer = document.getElementById(
      "fullscreen-container-aida"
    )
    if (!fullscreenContainer) {
      fullscreenContainer = document.createElement("div")
      fullscreenContainer.id = "fullscreen-container-aida"
      document.body.appendChild(fullscreenContainer)
    }
    fullScreenDiv.current = fullscreenContainer
  }, [])

  return (
    <Portal container={fullScreenDiv.current}>
      <div className="fullscreen-image">
        <button type="button" onClick={onClose} aria-label="Stäng">
          X
        </button>
        <img role="presentation" src={src} alt={alt} />
      </div>
    </Portal>
  )
}

export default FullscreenImage
