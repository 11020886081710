import React, { Fragment, useState } from "react";
import Slider from "./Slider";
import FullscreenSlider from "./FullscreenSlider";

const ImageSlider = ({ block, contentState, className, readOnly }) => {
  const { slides, settings } = contentState
    .getEntity(block.getEntityAt(0))
    .getData();
  const [fullscreen, toggleFullscreen] = useState(false);

  return (
    <Fragment>
      <Slider
        slides={slides}
        settings={settings}
        clickHandler={toggleFullscreen}
      />
      {fullscreen && (
        <FullscreenSlider
          slides={slides}
          settings={settings}
          onClose={() => toggleFullscreen(false)}
        />
      )}
    </Fragment>
  );
};

export default ImageSlider;
