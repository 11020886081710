import AudioComponent from "./Audio";
import React from "react";

function createAudioPlugin({ className }) {
  return {
    blockRendererFn: (block, { getEditorState, getReadOnly }) => {
      if (block.getType() !== "atomic") {
        return null;
      }

      const contentState = getEditorState().getCurrentContent();
      const entity = block.getEntityAt(0);

      if (!entity) {
        return null;
      }

      const type = contentState.getEntity(entity).getType();

      if (type !== "AUDIO" && type !== "audio") {
        return null;
      }

      const Audio = (props) => (
        <AudioComponent
          className={className}
          readOnly={getReadOnly()}
          {...props}
        />
      );

      return {
        component: Audio,
        editable: false,
      };
    }
  };
}

export default createAudioPlugin;
