import { IconType } from "./index";

const LinkExternal = ({ size, color } : IconType) => (
  <svg
    viewBox="0 0 32 32"
    width={`${size}px`}
    fill={color}
    className="link-external-icon"
  >
  <g stroke="currentColor" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
    <path d="M25.168 13.22V6.831h-6.39M25.168 6.831l-10 10M14.936 9.609H8.131c-.716 0-1.297.58-1.297 1.296v12.963c0 .716.58 1.296 1.297 1.296h12.963c.716 0 1.296-.58 1.296-1.296v-6.805"/>
  </g>
</svg>
);

export default LinkExternal;