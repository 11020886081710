import { CompositeDecorator, EditorState } from "draft-js";
import Link from "./Link";

export const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};



const showLinkDecorator = (editorState) => {
  const linkDecorator = new CompositeDecorator([
    {
      strategy: linkStrategy,
      component: Link
    }
  ]);
  return EditorState.set(editorState, {decorator: linkDecorator});
}

export default showLinkDecorator;
