import { useEffect, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";

export interface Data {
  content: Content | null;
  selectableContent: {
    title: string | null;
    content: ContentArr;
  } | null;
  selectableTags: string[] | null;
  onSelectableTagClick: (tag: string) => void;
  onSelectableContentClick: (contentToShow: Content) => void;
  goBackToSelectableContent: (data: SelectableContent) => void;
  goBackToTags: () => void;
}

interface Props {
  data: ContentArr;
  tags: string[];
}

export type SelectableContent = {
  title: string | null;
  content: ContentArr;
} | null;

export type Content = {
  tags: string[];
  content: any;
  title: string;
};

export type ContentArr = Content[];

function useHelpPreview({ data, tags }: Props): Data {
  const [content, setContent] = useState<null | Content[]>(null);
  const [currentContent, setCurrentContent] = useState<null | Content>(null);
  const [selectableTags, setSelectableTags] = useState<null | string[]>(null);
  const [
    selectableContent,
    setSelectableContent,
  ] = useState<null | SelectableContent>(null);

  useEffect(() => {
    if (!content) {
      setContent(data);
      filterContent(tags, data);
    }
  }, [setContent]);

  const convertToDraftJs = (contentToConvert: Content) => {
    const parsedContent = JSON.parse(contentToConvert.content);
    const raw = convertFromRaw(parsedContent);
    const editorState = EditorState.createWithContent(raw);
    setCurrentContent({ ...contentToConvert, content: editorState });
  };

  const filterByTags = (filterTags: string[], contentToFilter: ContentArr) => {
    if (filterTags.length === 0) {
      return contentToFilter;
    }
    return contentToFilter.filter((contentPost) =>
      filterTags.some((tag) => contentPost.tags.includes(tag))
    );
  };

  const filterContent = (filterTags: string[], contentToFilter: ContentArr) => {
    setCurrentContent(null);
    setSelectableContent(null);
    setSelectableTags(null);
    const uniqueTags = findTags(contentToFilter);
    if (uniqueTags.length > 0 && !filterTags.length) {
      setSelectableTags(uniqueTags);
      return;
    }

    const pageContent = filterByTags(filterTags, contentToFilter);
    if (pageContent.length === 1) {
      convertToDraftJs(pageContent[0]);
      return;
    }
    setSelectableContent({
      title: filterTags.length === 1 ? filterTags[0] : null,
      content: pageContent,
    });
  };

  const findTags = (contentArr: ContentArr) => {
    const tags = contentArr.reduce((acc, post) => {
      const posttags = post.tags.reduce((a, t) => {
        if (acc.indexOf(t) === -1 && a.indexOf(t) === -1) {
          a = [...a, t];
        }
        return a;
      }, [] as string[]);
      acc = [...acc, ...posttags];
      return acc;
    }, [] as string[]);

    return tags;
  };

  const onSelectableTagClick = (tag: string) => {
    filterContent([tag], content as ContentArr);
    return;
  };

  const onSelectableContentClick = (contentToShow: Content) => {
    convertToDraftJs(contentToShow);
  };

  const goBackToSelectableContent = (data: SelectableContent) => {
    setCurrentContent(null);
    setSelectableContent(data);
  };

  const goBackToTags = () => {
    filterContent([], content as ContentArr);
  };

  return {
    content: currentContent,
    selectableTags,
    selectableContent,
    goBackToSelectableContent,
    goBackToTags,
    onSelectableContentClick,
    onSelectableTagClick,
  };
}

export default useHelpPreview;
