import React from "react";

const ChevronLeft = ({ size }) => (
  <svg
    xmlns=" http://www.w3.org/2000/svg "
    viewBox="0 0 50 50"
    width={`${size}px`}
    className="studlicon-chevronleft"
  >
    <path d="M36.8195634,49.6059879 L37.6080823,48.8135645 C38.1306392,48.2883457 38.1306392,47.4367427 37.6080823,46.9114119 L15.80778,24.999951 L37.6080823,3.08848999 C38.1306392,2.56327129 38.1306392,1.71166827 37.6080823,1.18633748 L36.8195634,0.393914031 C36.2970065,-0.131304677 35.4497194,-0.131304677 34.9271625,0.393914031 L11.3919177,24.0489307 C10.8693608,24.5741495 10.8693608,25.4257525 11.3919177,25.9510833 L34.9271625,49.6061 C35.4497194,50.1313187 36.2970065,50.1313187 36.8195634,49.6059879 Z" />
  </svg>
);

export default ChevronLeft;
