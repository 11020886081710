import { useEffect, useRef } from "react"
import useHelpPreview, { ContentArr } from "./useHelpPreview"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Draggable from "react-draggable"
import {
  Dialog,
  Portal
} from "@material-ui/core"
import "../../shared/css/help.css"
import { PositionProps } from "../HelpContent/HelpContent"
import HelpContent from "../HelpContent"

type Props = {
  anchorEl: HTMLButtonElement | null
  open: boolean
  tags: string[]
  closeHelp: () => void
  position: PositionProps
  data: ContentArr
  supportURL: string
}

const HelpPreview = ({
  anchorEl,
  open,
  tags,
  closeHelp,
  position,
  data,
  supportURL
}: Props) => {
  const {
    content,
    selectableContent,
    selectableTags,
    onSelectableTagClick,
    onSelectableContentClick,
    goBackToTags,
    goBackToSelectableContent
  } = useHelpPreview({ tags, data })
  const isDesktop = useMediaQuery("(min-width:600px)")
  const helpContainer = useRef<HTMLDivElement>()
  const nodeRef = useRef(null)

  useEffect(() => {
    let helpContainerAida = document.getElementById("help-container-aida")
    if (!helpContainerAida) {
      helpContainerAida = document.createElement("div")
      helpContainerAida.id = "help-container-aida"
      document.body.appendChild(helpContainerAida)
    }
    helpContainer.current = helpContainerAida as HTMLDivElement
  }, [])

  const goToSupport = () => {

    const encodedURI = encodeURIComponent(window.location.href)
    const redirectURL = `${supportURL}/?url=${encodedURI}#supportform`
    window.open(redirectURL, "_blank", "noopener")
  }

  const onBackClick = (e: any) => {
    e.stopPropagation()

    if (selectableContent && !content) {
      goBackToTags()
    }
    if (content && !selectableContent) {
      goBackToTags()
    }
    if (selectableContent && content) {
      goBackToSelectableContent(selectableContent)
    }
  }

  return (
    <>
      {open && isDesktop && (
        <>
          {helpContainer.current && anchorEl && (
            <Portal container={helpContainer.current}>
              <Draggable
                handle=".handle"
                positionOffset={{
                  x: (anchorEl.getBoundingClientRect().x as unknown) as string,
                  y: (anchorEl.getBoundingClientRect().y as unknown) as string
                }}
                nodeRef={nodeRef}
              >
                <div style={{ position: "fixed" }} ref={nodeRef}>
                  <HelpContent
                    isDesktop={isDesktop}
                    onBackClick={onBackClick}
                    content={content}
                    selectableTags={selectableTags}
                    selectableContent={selectableContent}
                    onSelectableContentClick={onSelectableContentClick}
                    onSelectableTagClick={onSelectableTagClick}
                    goToSupport={goToSupport}
                    closeHelp={closeHelp}
                    position={position}
                  />
                </div>
              </Draggable>
            </Portal>
          )}
        </>
      )}
      {open && !isDesktop && (
        <Dialog open={open} fullScreen={true}>
          <HelpContent
            isDesktop={isDesktop}
            onBackClick={onBackClick}
            content={content}
            selectableTags={selectableTags}
            selectableContent={selectableContent}
            onSelectableContentClick={onSelectableContentClick}
            onSelectableTagClick={onSelectableTagClick}
            goToSupport={goToSupport}
            closeHelp={closeHelp}
            position={position}
          />
        </Dialog>
      )}
    </>
  )
}

export default HelpPreview
