
import { PositionOptions } from "../../components/HelpContent/HelpContent"

export const getWindowPosition = (rect: any, windowPosition: PositionOptions) => {
  if (!rect) return {}
  const calcedRect = rect.getBoundingClientRect()
  switch (windowPosition) {
    case "topRight":
      return { top: `0px`, left: `-${calcedRect.width}px` }
    case "topLeft":
      return { top: `0px`, left: `${calcedRect.width}px` }
    case "bottomRight":
      return { top: `-${calcedRect.height}px`, left: `-${calcedRect.width}px` }
    case "bottomLeft":
      return { top: `-${calcedRect.height}px`, left: `${calcedRect.width}px` }
  }
}